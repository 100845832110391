import { isEmpty } from 'ramda';
import { OrganizationApp } from 'src/reducers/organizations';
import { SurveyMultiAppsModel } from 'src/reducers/surveys-multi-apps/survey-multi-apps.model';
import { AppInteractionData, Platform, SdkVersion, SelectedApps } from 'src/types/core';

export type ExtendedOrganizationApp = OrganizationApp & { isWarningVersion?: boolean };

const MAU_FOR_WEB_PLATFORM = -1;

export const ALL_PLATFORMS_OPTION = 'All';

export const getAppLatestSDKVersion = (app: OrganizationApp) =>
  (app.latestSDKVersion && Number(app.latestSDKVersion.split('.').slice(0, 2).join('.'))) || 0;

export const getImtSDKVersionNumber = (imtSdkVersion: SdkVersion) => Number(imtSdkVersion.replace('v', ''));

export const getFormattedMau = (app: OrganizationApp, isWeb: boolean) => {
  if (isWeb) {
    return MAU_FOR_WEB_PLATFORM;
  }

  return app.mau ? Math.round(app.mau) : 0;
};

export const getExtendedOrgApp = (app: OrganizationApp, imtSdkVersion?: SdkVersion): ExtendedOrganizationApp => {
  const appObj = app || ({} as OrganizationApp);
  const isWeb = appObj.platform === Platform.Web;
  const isWarningVersion =
    !isWeb && imtSdkVersion && getAppLatestSDKVersion(appObj) < getImtSDKVersionNumber(imtSdkVersion);

  return {
    ...appObj,
    mau: getFormattedMau(appObj, isWeb),
    isWarningVersion,
  };
};

export const getIsAppTargetingFromAnotherInteraction = (
  interactionData: AppInteractionData[],
  app: OrganizationApp,
) => {
  const { codePoints = [], triggeredBy = [] } = interactionData.find(({ appId }) => appId === app.id) || {};
  if (isEmpty(codePoints) || isEmpty(triggeredBy)) {
    return false;
  }

  return SurveyMultiAppsModel.isFromLoveDialog(codePoints);
};

export const getAppsToSelect = (
  orgApps: OrganizationApp[],
  selectedPlatforms: string[],
  interactionData: AppInteractionData[],
  appId: string,
  isNewInteraction: boolean,
  getIsUserHasFullAccessToApp: (appId: string) => boolean,
): OrganizationApp[] => {
  let appsToSelect = orgApps;
  const hasSelectedAll = selectedPlatforms.includes(ALL_PLATFORMS_OPTION);

  const platforms = [Platform.iOS, Platform.Android, Platform.Web];
  const isAllAppsSelectedByPlatform = Object.fromEntries(
    platforms.map((platform) => [
      platform,
      interactionData.filter((app) => app.platform === platform && !app.isRemoved).length ===
      orgApps.filter((app) => app.platform === platform).length,
    ])
  );
  const filteredAppsFromIntData = interactionData.filter(
    ({ platform, isRemoved }) => !selectedPlatforms.includes(platform) && !isRemoved && !isAllAppsSelectedByPlatform[platform]
  );
  const remainedApps = orgApps.filter((app) =>
    filteredAppsFromIntData.some(({ appId }) => appId === app.id) && !getIsAppTargetingFromAnotherInteraction(interactionData, app)
  );

  if (!hasSelectedAll) {
    const appsToSelectByPlatform = orgApps.filter(
      (app) =>
        selectedPlatforms.includes(app.platform) || getIsAppTargetingFromAnotherInteraction(interactionData, app),
    );
    appsToSelect = [...remainedApps, ...appsToSelectByPlatform];
  }

  const currentApp = orgApps.find((app) => app.id === appId);
  if (currentApp && isNewInteraction) {
    const isAppSelected = appsToSelect.some((app) => app.id === appId);

    if (!hasSelectedAll && !isAppSelected && selectedPlatforms.length === 0) {
      appsToSelect = [currentApp, ...remainedApps];
    }

    if (!isAppSelected && (hasSelectedAll || selectedPlatforms.length > 0)) {
      appsToSelect = isNewInteraction ? [currentApp, ...remainedApps, ...appsToSelect] : [...remainedApps, ...appsToSelect];
    }
  }

  return appsToSelect.filter(({ id }) => getIsUserHasFullAccessToApp(id));
};

export const getSelectedApps = (
  app: OrganizationApp,
  interactionData: AppInteractionData[],
): SelectedApps[] => {
  const isSelected = interactionData.some(
    ({ appId, isRemoved }) => appId === app.id && !isRemoved,
  );

  let updatedSelectedApps: SelectedApps[] = interactionData
    .filter(({ isRemoved }) => !isRemoved)
    .map(({ appId, platform }) => ({ appId, platform }));

  isSelected
    ? (updatedSelectedApps = updatedSelectedApps.filter(({ appId }) => appId !== app.id))
    : updatedSelectedApps.push({ appId: app.id, platform: app.platform });

  return updatedSelectedApps;
};
